export default defineNuxtRouteMiddleware((_to, _from) => {
  const user = useSupabaseUser()

  const { userData, userCoreId, mainSearchExpertRadius, mainSearchLocationAddress, mainSearchLocationLatitude, mainSearchLocationLongitude } = storeToRefs(useUserDataStore())
  const { logout } = useFetchComposable()

  if (!user.value?.id) {
    return navigateTo('/')
  }

  watch(user, () => {
    if (!user.value?.id) {
      return navigateTo('/')
    }
    else if (user.value.id && userData.value?.is_first_login) {
      logout()

      userData.value = null
      userCoreId.value = ''
      mainSearchExpertRadius.value = 10000
      mainSearchLocationAddress.value = '영등포구'
      mainSearchLocationLatitude.value = 37.526618
      mainSearchLocationLongitude.value = 126.919666
    }
  }, {
    immediate: true,
  })
})
